import React from "react"
import { Helmet } from "react-helmet"
import Header from "../components/landing-pages/blocks/header.js"
import CtaItem from "../components/landing-pages/blocks/cta.js"
import HeroGreenSection from "../components/landing-pages/hero-green-section.js"
import CaseStudyGreenSection from "../components/landing-pages/case-study-green-section.js"
import BrandSection from "../components/landing-pages/your-brand-section.js"
import YourOrganicSection from "../components/landing-pages/your-organic-section.js"
import WhyNextCoGreenSection from "../components/landing-pages/why-nextco-green-section.js"
import SearchPresenceSection from "../components/landing-pages/search-presence-section.js"
import FAQSection from "../components/landing-pages/faq-section.js"
import FormSection from "../components/landing-pages/form-green-section.js"

import "../scss/landing.scss"

export default function SearchEnginePage({ data }) {
  const metadescription =
    "Next&Co are an independent digital and traditional performance marketing agency, visit our site or call 1300 191 950"
  const metatitle = "Search Engine Optimisation - TheNext"
  const metaurl = typeof window !== "undefined" ? window.location.href : ""

  const faqs = [
    {
      question: "How much could SEO be worth to my brand?",
      answer:
        "We can help you answer this question. We can help you quantify what the total addressable market is in your particular category and develop a realistic roadmap of how your brand can look to capture share of voice",
    },
    {
      question: "How do I know if an agency is a fit for my brand?",
      answer:
        "Whilst there is no one-size fits all answer to this question it is very important to assess a potential fit with a potential agency.  Aside from evaluating what their specific strategy for your brand would be (avoid generic one-size-fits-all packages) and experience in your category it is equally important to assess if you have a good working chemistry with the team that would be looking after you.  Reputable SEO agencies generally love to educate and demystify the organic search space so should be happy to answer any questions you have.",
    },
    {
      question:
        "How do you measure the effectiveness of an organic search strategy?",
      answer:
        "<p>Whilst there are various ways to assess the effectiveness of an organic search strategy, a common methodology can look like:</p><ul><li>Assess uplift in rankings (from target keywords)</li><li>Assess uplift in traffic (both organic & direct)</li><li>Assess uplift in conversions/engagements</li></ul><p>It is recommended that each strategy have its own bespoke metrics and KPI’s</p>",
    },
    {
      question: "How much does SEO cost?",
      answer:
        "<p>The age old question.</p><p>Well…it depends.</p><p>Our SEO programs are priced based on the resourcing gap between your domain and the competitors that currently rank for the keywords your brand is targeting.  We quantify the gap and price a monthly retainer to get you results in the quickest, safest way possible.</p>",
    },
    {
      question: "How long does it take to get top rankings?",
      answer:
        "<p>Another age old question.</p><p>Well…it depends.</p><p>Just as variable as the ‘cost’ of SEO, a timeframe taken to get good rankings can be variable depending on the gap between your brand and the websites currently ranked for your target keywords.  A timeline to bridge this gap safely would be variable.  We do advise clients that SEO is a medium - long term play that can help grow your brand VS something you do for immediate results.</p>",
    },
    {
      question:
        "Can you benchmark my existing search presence and that of my competitors?",
      answer:
        "Yes. We have access to several tools (both proprietary and third party) that can help you get a better understanding of your current state of search presence. Just get in touch with us and request an ‘SEO audit’ and we will be happy to help.",
    },
  ]

  return (
    <>
      <Helmet>
        <title>{metatitle}</title>
        <meta name="description" content={metadescription} />
        <meta name="title" content={metatitle} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={metaurl} />
        <meta property="og:title" content={metatitle} />
        <meta property="og:description" content={metadescription} />
        <meta property="og:image" content="facebook.png" />
        <meta property="twitter:url" content={metaurl} />
        <meta property="twitter:title" content={metatitle} />
        <meta property="twitter:description" content={metadescription} />
        <meta property="twitter:image" content="twitter.png" />
      </Helmet>

      <div id="nd-ld-page" className="nc-landing-page green">
        <Header />

        <HeroGreenSection />
        <CaseStudyGreenSection />
        <CtaItem
          heading="Get Measurably Better™ performance from your organic search strategy"
          ctaLink="/contact"
          ctaText="LET’S TALK"
        />
        <BrandSection />
        <YourOrganicSection />
        <WhyNextCoGreenSection />
        <SearchPresenceSection />
        <FAQSection faqs={faqs} />
        <FormSection heading="Benchmark your brands current state of organic search " />
      </div>
    </>
  )
}
